export const imageUrl = "https://apidata.coachingtest.com/uploads/"
export const MainUrl = "https://apidata.coachingtest.com/auth/createSecure" //195.35.45.56:8002
// export const imageUrl = "http://localhost:8002/uploads/"
// export const MainUrl = "http://localhost:8002/auth/createSecure"
// export const imageUrl = "https://api.coachingtest.com/uploads/"
// export const MainUrl = "https://api.coachingtest.com/auth/createSecure"
export const endPoint = {
    checkTokenValidation:"/check-authentication",
    addUser:"/add-user",
    getUser:"/get-users",
    updateUser:"/update-user",
    deleteUser:"/delete-user",
    categoryAdd:"/addCategory",
    categoryGet:"/getCategory",
    updateCategory:"/updateCategoryById",
    deleteCategory:"/deleteCategoryById",
    bookList:"/getBookList",
    addBookDetails:"/addBookDetails",
    updateBookDetails:"/updateBookDetailsById",
    deleteBookById:"/deleteBookDetailsById",
    addPoster:"/addPoster",
    updatePoster:"/updatePosterById",
    getPoster:"/getPoster",
    deletePoster:"/deletePosterById",
    login:"/login",
    forgotPassword:"/forgot-password",
    newsAdd:"/addNews",
    newsLetterGet:"/getNewsletterData",
    newsUpdate:"/updateNewsById",
    newsDelete:"/deleteNewsById",
    changeCategoryStatus:"/changeCategoryStatus",
    changeBookStatus:"/changeBookStatus",
    changePosterStatus:"/changePosterStatus",
    addSubadmin:"/subadmin",
    permissions:"/get-permission",
    getSubadminList:"/get-subadmin-list",
    changeSubadminStatus:"/subadminstatus",
    subadmindelete: '/deletesubuser',
    updateSubadmin: '/updatesudadmin',
    getAllReview:"/getAllReviews",
    changeReviewStatus:"/reviewStatusChange",
    getAllCartInfo:"/getAllCartDetails",
    getAllSupportRequest:"/getAllSupportRequest",
    setTrendingTitleImage:"/setTitlesImage",
    getTrandingTitleImagesData:"/getTitleData",
    titleImageUpdateById:"/updateTitleDataById",
    titleImageDeleteById:"/deleteTitleDataById",
    addPromotionAndOfferDetails:"/addPromotionAndOfferDetails",
    getPromotionAndOfferDetails:"/getPromotionAndOfferDetails",
    updatePromotionAndOfferDetails:"/updatePromotionAndOfferDetails",
    deletePromotionAndOfferDetails:"/deletePromotionAndOfferDetails",
    addAdminInformation:"/addAdminInformation",
    getAdminInformation:"/getAdminInformation",
    updateAdminInformation:"/updateAdminInformation",
    addSocialMediaurl:"/addSocialMediaurl",
    getSocialMediaurl:"/getSocialMediaurl",
    updateSocialMediaurlById:"/updateSocialMediaurlById",
    deleteSocialMediaurlById:"/deleteSocialMediaurlById",
    addCurrentAffairs:"/addCurrentAffairsFile",
    getCurrentAffairs:"/getCurrentAffairsFile",
    updateCurrentAffairs:"/updateCurrentAffairsFile",
    deleteCurrentAffairs:"/deleteCurrentAffairsFile",
    addTestSeries:"/addTestSeries",
    getTestSeries:"/getTestSeries",
    updateTestSeries:"/updateTestSeries",
    deleteTestSeries:"/deleteTestSeries",
    addBookFiles:"/addBookFiles",
    getBookFiles:"/getBookFiles",
    updateBookFiles:"/updateBookFiles",
    deleteBookFiles:"/deleteBookFiles",
    getDesign:"/getDesign",
    addDesign:"/addDesign",
    updateDesign:"/updateDesign",
    deleteDesign:"/deleteDesign",
    addPreviousYearPaper:"/addPreviousYearPaper",
    updatePreviousYearPaper:"/updatePreviousYearPaper",
    getPreviousYearPaper:"/getPreviousYearPaper",
    deletePreviousYearPaper:"/deletePreviousYearPaper",
    getAllTypingQuery:"/getAllTypingQuery",
    getAllDataTranslateQuery:"/getAllDataTranslateQuery",
    addFlashMessage:"/setFlashMessage",
    getFlashMessage:"/getFlashMessage",
    updateFlashMessage:"/updateFlashMessage",
    removeFlashMessage:"/deleteFlashMessage",
    addPermotionPopupData:"/setPermotionPopupData",
    getPermotionPopupData:"/getPermotionPopupData",
    updatePermotionPopupData:"/updatePermotionPopupData",
    removePermotionPopupData:"/deletePermotionPopupData"


}
