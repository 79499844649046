const Notfound = () => {
    return (
        <div class="container">
    <h1>404</h1>
    <p>Oops! The page you're looking for cannot be found.</p>
    <a href="/dashboard">Go back to the homepage</a>
  </div>
    )
}

export default Notfound