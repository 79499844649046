import logo from './logo.svg';
import './App.css';
import Routes  from './Routes/Routes';
import { ProfileProvider } from './component/ui/contextProvider';
import { useEffect } from 'react';
import { LogoutAfterTokenExpire } from './context/logout.js';
import { Toaster } from 'react-hot-toast';


function App() {
  return (
    <>
    <LogoutAfterTokenExpire>
    <ProfileProvider>
      <Routes/>
    </ProfileProvider>
    </LogoutAfterTokenExpire>
    <Toaster/>
    </>
  );
}

export default App;
